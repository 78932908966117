import React from 'react'
import { graphql } from 'gatsby'
import { Container, ListItem, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  AttachMoney,
  CalendarToday,
  Bookmark,
  AccessTime,
} from '@material-ui/icons'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Table from '../components/CourseTable'
import { PageTitle } from '../components/PageTitle'

import { SubscribeBtn } from '../components/CourseWrapper/styles'

const useStyles = makeStyles(theme => ({
  xLarge: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  m1: {
    margin: '10px 0',
  },
  floatRight: {
    float: 'right',
  },
  text: {
    color: 'rgb(102, 102, 102)',
    lineHeight: '1.85em',
    wordSpacing: '5px',
    textAlign: 'justify',
  },
  subscibeSection: {
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
  },
  subscribeButton: {
    margin: '5px 0',
  },
  linkMatriz: {
    display: 'flex',
    fontSize: 20,
    color: '#007bff',
    width: 'fit-content',
    '&:hover': {
      textDecoration: 'underline !important'
    }
  }
}))

export default ({ data }) => {
  const post = data.markdownRemark
  const classes = useStyles()
  const disciplinas = post.frontmatter.disciplinas
  const horas = post.frontmatter.horas

  return (
    <Layout>
      <SEO title={post.frontmatter.name} />
      <Container style={{ marginTop: 60 }}>
        <PageTitle>{post.frontmatter.name}</PageTitle>
        <Grid container>
          <Grid lg={12} md={12}>
            <ListItem disableGutters="true" style={{ marginTop: 10 }}>
              <CalendarToday fontSize="small" /> Duração:{' '}
              {post.frontmatter.duration}
            </ListItem>
            {horas && (
              <ListItem disableGutters="true">
                <AccessTime /> Carga horária:{' '}
                {horas.reduce((h, value) => h + value)}H
              </ListItem>
            )}
            <ListItem disableGutters="true">
              <Bookmark /> Mensalidades: {post.frontmatter.parcelas}
            </ListItem>
            <ListItem disableGutters="true">
              <AttachMoney /> Mensalidades a partir de: R$
              {post.frontmatter.price} *
            </ListItem>
            <ListItem disableGutters="true">
              {post.frontmatter.link_matriz && (
                <a className={classes.linkMatriz} href={post.frontmatter.link_matriz} target="_blank">Matriz de {post.frontmatter.name}</a>
              )}
            </ListItem>

            {post.frontmatter.about &&
              <>
                <Typography className={classes.m1} variant="h5">
                  Sobre o curso
                </Typography>

                <Typography
                  className={(classes.m1, classes.text)}
                  variant="body2"
                  paragraph
                  style={{ fontSize: 16 }}
                >
                  {post.frontmatter.about}
                </Typography>
              </>
            }

            <Typography className={classes.m1} variant="h5">
              Objetivos
            </Typography>

            {post.frontmatter.objetivo_especifico === null ?
              <Typography
                className={(classes.m1, classes.text)}
                variant="body2"
                paragraph
                style={{ fontSize: 16 }}
              >
                {post.frontmatter.objetivo}
              </Typography> :
              <>
                <Typography className={classes.m1} variant="h5">
                  Geral
                </Typography>
                <li
                  className={(classes.m1, classes.text)}
                  variant="body2"
                  paragraph
                  style={{ fontSize: 16 }}
                >
                  {post.frontmatter.objetivo_geral}
                </li>
                <Typography className={classes.m1} variant="h5">
                  Específico
                </Typography>
                {post.frontmatter.objetivo_especifico.map(obj => (
                  <li
                    className={(classes.m1, classes.text)}
                    variant="body2"
                    paragraph
                    style={{ fontSize: 16 }}
                  >
                    {obj}
                  </li>
                ))}
              </>
            }

            <Typography
              className={classes.m1}
              variant="h5"
              style={{ marginTop: 20 }}
            >
              Público-alvo
            </Typography>
            <Typography
              className={(classes.m1, classes.text)}
              variant="body2"
              paragraph
              style={{ fontSize: 16 }}
            >
              {post.frontmatter.category === 'tecnico' ? 'Pela legislação atual, os Cursos Técnicos fazem parte da Educação Profissional Técnica de Nível Médio. Dessa forma a conclusão do Nível Médio é obrigatória e pré-requisito, mas o Curso Técnico pode ser realizado de forma concomitante ao Nível Médio, desde que o Nível Médio seja concluído antes ou ao mesmo tempo que o Curso Técnico.'
                : post.frontmatter.publico}
            </Typography>

            <span style={{ fontSize: 15, color: '#555' }}>
              *Valor já com desconto promocional válido para pagamento até o vencimento
            </span>

            {disciplinas && (
              <>
                <Typography
                  className={classes.m1}
                  variant="h5"
                  style={{ marginTop: 20 }}
                >
                  Matriz curricular
                </Typography>
                <Table subjects={disciplinas} hours={horas} />
              </>
            )}

            <div className={classes.subscibeSection}>
              <SubscribeBtn
                className={classes.subscribeButton}
                href="https://conteudo.faculdadejardins.com.br/matricula"
                target="_blank"
                style={{ backgroundColor: '#ef4e45', border: '#ef4e45' }}
              >
                Quero mais informações
              </SubscribeBtn>

              {post.frontmatter.category === 'pos-graduacao ead' && (
                <SubscribeBtn
                  className={classes.subscribeButton}
                  href={post.frontmatter.link ? post.frontmatter.link : 'https://pag.ae/7VUxxu29q'}
                  target="_blank"
                >
                  Matricule-se
                </SubscribeBtn>
              )}

              {post.frontmatter.category === 'tecnico' && (
                <SubscribeBtn
                  className={classes.subscribeButton}
                  href="https://pag.ae/7WnZrPbpt"
                  target="_blank"
                >
                  Matricule-se
                </SubscribeBtn>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        name
        price
        duration
        disciplinas
        link_matriz
        horas
        parcelas
        about
        objetivo
        objetivo_geral
        objetivo_especifico
        publico
        category
        link
      }
      html
    }
  }
`
